<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit(updatedProductGroup)">
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="productName">
                  Product Group
                </label>
                <AutoCompleteInput
                  type="text"
                  placeholder="Choose a Product Group"
                  :chosen="{ value: updatedProductGroup.productName }"
                  :options="getCustomersProductGroups"
                  @choice="setProductGroupID"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="costPrice">Cost Price</label>
                <input
                  id="costPrice"
                  name="costPrice"
                  placeholder="Enter Cost Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="productGroup.costPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="sellingPrice">
                  Selling Price
                </label>
                <input
                  id="sellingPrice"
                  name="sellingPrice"
                  placeholder="Enter Selling Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="productGroup.sellingPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="updatedPrice"
                  >Updated Price</label
                >
                <input
                  id="updatedPrice"
                  name="updatedPrice"
                  placeholder="Enter Updated Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="updatedProductGroup.updatedPrice"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="suggestedRetailPrice">
                  Suggested Retail Price
                </label>
                <input
                  id="suggestedRetailPrice"
                  name="suggestedRetailPrice"
                  placeholder="Enter Suggested Retail Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="productGroup.suggestedRetailPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="updatedSuggestedRetailPrice">
                  Updated Suggested Retail Price
                </label>
                <input
                  id="updatedSuggestedRetailPrice"
                  name="updatedSuggestedRetailPrice"
                  placeholder="Enter Updated Suggested Retail Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="updatedProductGroup.updatedSuggestedRetailPrice"
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import { SETTINGS } from "@/constants/config";

export default {
  name: "CustomerProductGroupItemForm",
  components: { AutoCompleteInput },
  props: {
    priceGroupItem: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
    disabledList: {
      type: Set,
      default: () => new Set(),
    },
  },
  data() {
    return {
      productGroup: {},
      updatedProductGroup: { ...this.productGroup },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        delete this.updatedProductGroup.value;
        this.updatedProductGroup.price = this.updatedProductGroup.updatedPrice;
        delete this.updatedProductGroup.updatedPrice;
        this.updatedProductGroup.suggestedRetailPrice =
          this.updatedProductGroup.updatedSuggestedRetailPrice;
        delete this.updatedProductGroup.updatedSuggestedRetailPrice;
        this.submitFunction(this.updatedProductGroup);
      }
    },
    discard() {
      this.$router.push(
        `/priceGroups/${this.updatedProductGroup.priceGroupID}`
      );
      this.updatedProductGroup = { ...this.productGroup };
    },
    async getProductGroups(id, filter) {
      if (this.actionType === "edit") {
        await this.$store.dispatch("getCustomersProductGroups", {
          id,
          filter,
        });
      } else {
        await this.$store.dispatch("getProductGroups", filter);
      }
    },
    async setProductGroupID(value, productGroupID) {
      let id = parseInt(this.$route.params.id);
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInput,
        searchQuery: value,
      };
      await this.getProductGroups(id, filter);
      this.updatedProductGroup.value = value;
      this.updatedProductGroup.id = productGroupID;
      if (id > 0) {
        this.productGroup = { ...this.getCustomersProductGroups[0] };
        this.updatedProductGroup.updatedPrice = this.productGroup.updatedPrice;
        this.updatedProductGroup.updatedSuggestedRetailPrice =
          this.productGroup.updatedSuggestedRetailPrice;
      }
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
    getCustomersProductGroups() {
      let productGroups = this.$store.getters.allProductGroups;
      if (this.actionType === "edit") {
        productGroups = this.$store.getters.customersProductGroups;
        productGroups.forEach((productGroup) => {
          productGroup.name = productGroup.productGroup;
        });
      }
      return productGroups;
    },
  },
  async created() {
    this.updatedProductGroup.priceGroupID = parseInt(this.$route.params.id);
    let id = this.$route.params.id;
    let filter = {
      resultsPerPage: SETTINGS.resultsPerInput,
    };
    await this.getProductGroups(id, filter);
  },
  async unmounted() {
    await this.$store.commit("reset");
  },
};
</script>
