<template>
  <div class="modal-backdrop fade show" bis_skin_checked="1"></div>
  <div
    class="modal fade show"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    bis_skin_checked="1"
    style="display: block; padding-left: 0px"
    aria-modal="true"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      bis_skin_checked="1"
    >
      <div class="modal-content border-0" bis_skin_checked="1">
        <div class="modal-header p-4 pb-0" bis_skin_checked="1">
          <h4 class="modal-title" id="createMemberLabel">
            Add Payment ({{ invoiceSummary.invoiceNo }})
          </h4>
          <button
            type="button"
            class="btn-close"
            id="createMemberBtn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closePopup"
          />
        </div>
        <div class="modal-body p-4" bis_skin_checked="1">
          <form>
            <div class="row" bis_skin_checked="1">
              <div class="col-sm-auto col-md" bis_skin_checked="1">
                <div class="mb-3 row" bis_skin_checked="1">
                  <h5 class="col-sm-auto col-md text-center">
                    <strong>Invoice Total:</strong> ${{ invoiceSummary.total }}
                  </h5>
                  <h5 class="col-sm-auto col-md text-center">
                    <strong>Due:</strong>
                    ${{ invoiceSummary.balance }}
                  </h5>
                </div>

                <div class="mb-3" bis_skin_checked="1">
                  <label for="date" class="form-label">Date</label>
                  <DateInput v-model="payment.date" />
                </div>

                <div class="row" bis_skin_checked="1">
                  <div class="col-sm-auto col-md" bis_skin_checked="1">
                    <div class="mb-4" bis_skin_checked="1">
                      <label for="paymentType" class="form-label"
                        >Payment Type</label
                      >
                      <select
                        class="form-select"
                        id="paymentType"
                        v-model="payment.paymentType"
                        aria-label="Default select example"
                      >
                        <option value="">Select Payment Type</option>
                        <option value="cash">Cash</option>
                        <option value="cheque">Cheque</option>
                        <option value="credit">Credit</option>
                        <option value="moneyOrder">Money Order</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-auto col-md" bis_skin_checked="1">
                    <div class="mb-4" bis_skin_checked="1">
                      <label for="amount" class="form-label">Amount</label>
                      <input
                        type="number"
                        class="form-control"
                        id="amount"
                        v-model="payment.amountPaid"
                        placeholder="Enter Amount"
                      />
                    </div>
                  </div>
                </div>

                <div class="mb-3" bis_skin_checked="1">
                  <label for="chequeNo" class="form-label">Cheque No</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        payment.paymentType &&
                        payment.paymentType === 'cheque' &&
                        !payment.chequeNo,
                    }"
                    id="chequeNo"
                    v-model="payment.chequeNo"
                    placeholder="Enter Cheque No"
                  />
                  <input
                    class="form-control mt-2"
                    :class="{
                      'is-invalid':
                        payment.paymentType &&
                        payment.paymentType === 'cheque' &&
                        !payment.chequeNo,
                    }"
                    type="file"
                    id="fileInput"
                    name="cheque"
                    accept="image/*"
                    capture="camera"
                  />

                  <div class="invalid-feedback" bis_skin_checked="1">
                    Please enter the Cheque No.
                  </div>
                </div>

                <div class="row" bis_skin_checked="1">
                  <div class="col-sm-auto col-md" bis_skin_checked="1">
                    <div class="mb-4" bis_skin_checked="1">
                      <label for="discount" class="form-label">
                        Discount
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="discount"
                        v-model="payment.discountRedeemed"
                        placeholder="Enter Discount Amount"
                      />
                    </div>
                  </div>

                  <div class="col-sm-auto col-md" bis_skin_checked="1">
                    <div class="mb-4" bis_skin_checked="1">
                      <label for="awardPoints" class="form-label">
                        Award Points
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="awardPoints"
                        v-model="payment.awardPointsRedeemed"
                        placeholder="Enter Award Points"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="hstack gap-2 justify-content-end"
                  bis_skin_checked="1"
                >
                  <button
                    type="button"
                    class="btn btn-primary"
                    id="addNewMember"
                    @click="submit"
                    :disabled="
                      payment.paymentType &&
                      payment.paymentType === 'cheque' &&
                      !payment.chequeNo
                    "
                  >
                    Add Payment
                  </button>
                  <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                    @click="closePopup"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from "@/components/utils/DateInput";
export default {
  name: "AddPaymentModal",
  components: { DateInput },
  data() {
    return {
      payment: {},
    };
  },
  props: {
    invoiceSummary: {
      type: Object,
      required: true,
    },
    closePopup: {
      type: Function,
      required: true,
    },
  },
  computed: {},
  methods: {
    async submit() {
      this.payment.invoiceID = this.invoiceSummary.id;
      this.payment.amountPaid = this.payment.amountPaid
        ? this.payment.amountPaid
        : 0;
      this.payment.discountRedeemed = this.payment.discountRedeemed
        ? this.payment.discountRedeemed
        : 0;
      this.payment.amountDue = (
        this.invoiceSummary.balance -
        this.payment.amountPaid -
        this.payment.discountRedeemed
      ).toFixed(2);
      await this.$store.dispatch("addPayment", this.payment);
      this.closePopup();
      location.reload();
    },
  },
  created() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    // this.payment.date = `${month}-${day}-${year}`;
    this.payment.date = `${year}-${month}-${day}`;
    this.payment.amountPaid = this.invoiceSummary.balance;
    this.payment.discountRedeemed = 0;
    this.payment.awardPointsRedeemed = 0;

    if (this.invoiceSummary.billerID === 1) {
      this.payment.paymentType = "cash";
    } else if (this.invoiceSummary.billerID === 2) {
      this.payment.paymentType = "cheque";
    } else {
      this.payment.paymentType = "";
    }
  },
};
</script>

<style scoped></style>
