<template>
  <dialogue-modal
    v-if="paymentSelected"
    title="Are you sure you want to delete?"
    :message="paymentSelected.referenceNo"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Payment History" title="Payment History" />

  <div class="row pb-4 gy-3 d-print-none">
    <div class="col-sm-auto col-md-auto">
      <button class="btn btn-primary mx-2" @click="$router.go(-1)">
        <i class="las la-arrow-left me-1"></i> Back
      </button>
      <button @click="printPageAsPDF()" class="btn btn-secondary">
        <i class="ri-printer-cloud-line align-bottom me-1"></i> Print
      </button>
    </div>

    <div class="col-sm-auto col-md-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="startDate"> Start Date </label>
        <DateInput v-model="startDate" @input="goToPage(1)" />
      </div>
    </div>

    <div class="col-sm-auto col-md-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="endDate"> End Date </label>
        <DateInput v-model="endDate" @input="goToPage(1)" />
      </div>
    </div>

    <div class="col-sm-auto col-md-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto col-md-14">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data !== []">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              class="print"
              :headers="headers"
              :data="formattedData"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
              :actions="actions"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      class="d-print-none"
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import { SETTINGS } from "@/constants/config.js";
import { CONSTANTS } from "@/constants/constants.js";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import DateInput from "@/components/utils/DateInput";

export default {
  name: "PaymentHistoryView",
  components: {
    DateInput,
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      id: -1,
      filterBy: "",
      startDate: "",
      endDate: "",
      searchQuery: "",
      resultsPerPage: SETTINGS.resultsPerPage,
      paymentSelected: null,
    };
  },
  computed: {
    headers() {
      return CONSTANTS.DEFAULT_PAYMENT_HISTORY_FIELDS;
    },
    formattedData() {
      const paymentHistory = this.$store.getters.paymentHistory;
      return paymentHistory.map((payment) => ({
        ...payment,
        date: this.dateFormat(payment.date),
        total: this.priceFormat(payment.total),
        amountPaid: this.priceFormat(payment.amountPaid),
        amountDue: this.priceFormat(payment.amountDue),
        discountRedeemed: this.priceFormat(payment.discountRedeemed),
      }));
    },
    data() {
      return this.$store.getters.paymentHistory;
    },
    currentPage() {
      return this.$store.getters.paymentHistoryCurrentPage;
    },
    numPages() {
      return this.$store.getters.paymentHistoryNumPages;
    },
    totalRows() {
      return this.$store.getters.paymentHistoryTotalRows;
    },
    resultsPerPageOptions() {
      const options = CONSTANTS.RESULTS_PER_PAGE_OPTIONS;
      if (!options.includes(this.resultsPerPage)) {
        options.unshift(this.resultsPerPage);
      }
      return options;
    },
    actions() {
      return {
        "Download Cheque": (payment) => this.downloadCheque(payment),
        Delete: (payment) => this.showDeleteModal(payment),
      };
    },
    error() {
      return this.$store.getters.paymentHistoryError;
    },
    loading() {
      return this.$store.getters.paymentHistoryLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteInvoice(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.$store.dispatch(`get${this.filterBy}PaymentHistory`, {
        id: this.id,
        filter: filter,
      });
    },
    async printPageAsPDF() {
      window.print();
    },
    showDeleteModal(payment) {
      this.paymentSelected = payment;
    },
    downloadCheque(payment) {
      if (payment.chequeNo) {
        const chequeUrl = "https://www.starnovelty.us/" + payment.chequeNo;
        const link = document.createElement("a");
        link.href = chequeUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.click();
      } else {
        alert("This payment has no cheque associated with it!");
      }
    },
    async deleteInvoice() {
      if (this.paymentSelected) {
        await this.$store.dispatch("deletePayment", this.paymentSelected.id);
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.paymentSelected = null;
    },
  },
  async created() {
    this.filterBy = this.$route.meta.filterBy;
    this.id = parseInt(this.$route.params.id);
    let filter = {
      resultsPerPage: SETTINGS.resultsPerPage,
    };
    await this.$store.dispatch(`get${this.filterBy}PaymentHistory`, {
      id: this.id,
      filter: filter,
    });
  },
  beforeUnmount() {
    console.log("All Buyers Destroyed");
  },
};
</script>

<style scoped>
@media print {
  .card-body {
    padding-top: 5mm;
  }
}
</style>
