<template>
  <dialogue-modal
    v-if="showDelete"
    title="Are you sure you want to delete?"
    :message="invoiceDetails.invoiceNo"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Invoice" :title="invoiceDetails.invoiceNo" />

  <loading-modal v-if="loading" />
  <div v-else class="row justify-content-center">
    <div class="col-xxl-9">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="card-body p-4">
                <div :id="invoiceDetails.invoiceNo">
                  <div
                    v-if="
                      invoiceDetails.billerID === 2 && host.includes('lakejune')
                    "
                    class="text-center"
                    style="margin-bottom: 20px"
                    bis_skin_checked="1"
                  >
                    <img
                      src="/images/lakejune.png"
                      alt="LAKE JUNE BUSINESS INC"
                    />
                    <br />
                    11402 Harry Hines Blvd Dallas TEXAS 75229 <br />
                    (972)800-2958
                  </div>
                  <div
                    v-if="
                      invoiceDetails.billerID === 2 && host.includes('platinum')
                    "
                    class="text-center"
                    style="margin-bottom: 20px"
                    bis_skin_checked="1"
                  >
                    <img src="/images/logo.jpg" alt="STAR NOVELTY INC (2)" />
                    <br />
                    12114 Dunbeg Lane Richmond TEXAS 77407 <br />
                    (832)618-3060
                  </div>
                  <div
                    v-else-if="
                      invoiceDetails.billerID === 2 && host.includes('diamond')
                    "
                    class="text-center"
                    style="margin-bottom: 20px"
                    bis_skin_checked="1"
                  >
                    <img
                      src="/images/diamond_logo.jpg"
                      alt="EZ NOVELTY INC (2)"
                    />
                    <br />
                    3208 Venezia Vw , Leander TX <br />
                    5125764065
                  </div>
                  <div
                    v-else-if="
                      invoiceDetails.billerID === 2 && host.includes('silver')
                    "
                    class="text-center"
                    style="margin-bottom: 20px"
                    bis_skin_checked="1"
                  >
                    <img
                      src="/images/silver_logo.jpg"
                      alt="S&K Wholesale Inc"
                    />
                    <br />
                    13715 Glendon dr, Richmond, 77407 TX <br />
                    8326056990
                  </div>
                  <div
                    v-else-if="
                      invoiceDetails.billerID === 2 && host.includes('golden')
                    "
                    class="text-center"
                    style="margin-bottom: 20px"
                    bis_skin_checked="1"
                  >
                    <img
                      src="/images/golden_logo.jpg"
                      alt="Xpert Novelty Inc"
                    />
                    <br />
                    18238 port dundas drive, Richmond, 77407 TX <br />
                    8324195919
                  </div>
                  <div
                    class="row mb-2"
                    style="font-size: 10px"
                    bis_skin_checked="1"
                  >
                    <div class="col-6" bis_skin_checked="1">
                      <p class="mb-2"><strong>To:</strong></p>
                      <p class="fs-6 mb-2">
                        {{ invoiceDetails.customer }}
                      </p>
                      <p class="mb-2">
                        <strong>
                          {{ invoiceDetails.fullCustomerAddress }}</strong
                        >
                      </p>
                      <p class="mb-2">
                        <strong>
                          Tel: {{ invoiceDetails.customerPhone }}<br />
                          Email: {{ invoiceDetails.customerEmail }}</strong
                        >
                      </p>
                    </div>
                    <div class="col"></div>
                    <div class="col-2" bis_skin_checked="1">
                      <p class="mb-2">
                        <strong>
                          Date: {{ this.dateFormat(invoiceDetails.date) }}<br />
                          Reference: {{ invoiceDetails.invoiceNo }}<br />
                        </strong>
                      </p>
                    </div>
                  </div>

                  <div
                    class="table-responsive overflow-auto"
                    bis_skin_checked="1"
                  >
                    <table
                      class="table table-bordered table-hover table-striped print-table order-table"
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Quantity</th>
                          <th>Description</th>
                          <th>Barcode</th>

                          <th>Unit Price</th>
                          <th>Subtotal</th>
                          <th
                            :colspan="invoiceDetails.showSuggestedAnalysis + 1"
                          >
                            Sugg Price
                          </th>
                          <th
                            v-if="invoiceDetails.showIndividualMargin"
                            style="text-align: center; width: 60px"
                          >
                            %
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(row, index) in data" :key="index">
                          <td
                            style="
                              text-align: center;
                              width: 30px;
                              vertical-align: middle;
                            "
                          >
                            {{ index + 1 }}
                          </td>
                          <td
                            style="
                              width: 40px;
                              text-align: center;
                              vertical-align: middle;
                            "
                          >
                            {{ row.quantity }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ row.productName }}
                          </td>

                          <td
                            style="
                              width: 130px;
                              text-align: center;
                              vertical-align: middle;
                            "
                          >
                            <img
                              width="115"
                              height="32"
                              :src="`/images/barcodes/${row.barcode}.png`"
                              :alt="row.barcode"
                            />
                          </td>

                          <td style="text-align: right; width: 100px">
                            {{ priceFormat(row.sellingPrice) }}
                          </td>
                          <td style="text-align: right; width: 80px">
                            {{ priceFormat(row.subtotal) }}
                          </td>
                          <td
                            :colspan="invoiceDetails.showSuggestedAnalysis + 1"
                            style="text-align: right; width: 30px"
                          >
                            {{ priceFormat(row.suggestedRetailPrice) }}
                          </td>
                          <td
                            v-if="invoiceDetails.showIndividualMargin"
                            style="text-align: right; width: 60px"
                          >
                            {{
                              percentageFormat(
                                (1 -
                                  row.sellingPrice / row.suggestedRetailPrice) *
                                  100
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>

                      <tfoot style="display: table-row-group">
                        <tr
                          v-for="(value, key, index) in invoiceDetailsFooter"
                          :key="index"
                        >
                          <template v-if="index === 0">
                            <td></td>
                            <td style="text-align: center; font-weight: bold">
                              {{
                                data.reduce(
                                  (sum, item) => sum + item.quantity,
                                  0
                                )
                              }}
                            </td>
                            <td
                              colspan="3"
                              style="text-align: right; font-weight: bold"
                            >
                              {{ key }}
                            </td>
                          </template>
                          <template v-else>
                            <td
                              colspan="5"
                              style="text-align: right; font-weight: bold"
                            >
                              {{ key }}
                            </td>
                          </template>
                          <td style="text-align: right; font-weight: bold">
                            {{ value }}
                          </td>
                          <template
                            v-if="
                              index < Object.keys(suggestedAmountFooter).length
                            "
                          >
                            <td
                              :colspan="
                                invoiceDetails.showIndividualMargin === 0
                                  ? 1
                                  : 2
                              "
                              style="text-align: right; font-weight: bold"
                            >
                              {{ Object.keys(suggestedAmountFooter)[index] }}
                            </td>
                            <td style="text-align: right; font-weight: bold">
                              {{
                                suggestedAmountFooter[
                                  Object.keys(suggestedAmountFooter)[index]
                                ]
                              }}
                            </td>
                          </template>
                          <template v-else>
                            <td
                              :colspan="
                                invoiceDetails.showSuggestedAnalysis +
                                invoiceDetails.showIndividualMargin +
                                1
                              "
                              style="text-align: right; font-weight: bold"
                            ></td>
                          </template>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div class="row" bis_skin_checked="1">
                    <div class="col-xs-12" bis_skin_checked="1"></div>
                  </div>
                  <div v-if="invoiceDetails.notes" class="mt-4">
                    <div class="alert alert-info">
                      <p class="mb-0">
                        <span class="fw-semibold">Notes For Customer: </span>
                        <span id="note">
                          {{ invoiceDetails.notes }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-print-none mt-4" bis_skin_checked="1">
                  <div
                    class="col-sm-auto col-md-auto hstack gap-2 justify-content-md-end"
                  >
                    <router-link :to="`/invoices/`" class="btn btn-light">
                      Back
                    </router-link>
                    <router-link
                      :to="`/invoices/edit/${id}`"
                      class="btn btn-warning"
                    >
                      <i class="ri-mark-pen-line align-bottom me-1" />
                      Edit
                    </router-link>
                    <div
                      class="dropdown"
                      @mouseover="showPrintDropdown = true"
                      @mouseout="showPrintDropdown = false"
                    >
                      <button
                        class="btn btn-primary dropdown-toggle"
                        :class="{ show: showPrintDropdown }"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="ri-printer-cloud-line align-bottom me-1" />
                        Print
                      </button>
                      <div
                        class="dropdown-menu"
                        :class="{ show: showPrintDropdown }"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <router-link
                          class="dropdown-item"
                          :to="`/invoices/print/${id}`"
                          @click="showPrintDropdown = false"
                        >
                          Invoice
                        </router-link>
                        <router-link
                          class="dropdown-item"
                          :to="`/invoices/thermalPrint/${id}`"
                          @click="showPrintDropdown = false"
                        >
                          Thermal
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="dropdown"
                      @mouseover="showEDIDropdown = true"
                      @mouseout="showEDIDropdown = false"
                    >
                      <button
                        class="btn btn-success dropdown-toggle"
                        :class="{ show: showEDIDropdown }"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="ri-download-fill align-bottom me-1" /> EDI
                      </button>
                      <div
                        class="dropdown-menu"
                        :class="{ show: showEDIDropdown }"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          :href="`${BACKEND_URL}invoice/edi/modisoft/${id}/`"
                          @click="showEDIDropdown = false"
                          Download
                        >
                          Modi-Soft
                        </a>
                        <a
                          class="dropdown-item"
                          :href="`${BACKEND_URL}invoice/edi/cstore/${id}/`"
                          @click="showEDIDropdown = false"
                          Download
                        >
                          C-Store
                        </a>
                      </div>
                    </div>
                    <!--                    <button @click="emailInvoice()" class="btn btn-success">-->
                    <!--                      <i class="ri-send-plane-fill align-bottom me-1" />-->
                    <!--                      Email-->
                    <!--                    </button>-->
                    <button @click="showDeleteModal()" class="btn btn-danger">
                      <i class="bi-trash3-fill align-bottom me-1" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import LoadingModal from "@/components/utils/LoadingModal";
import html2pdf from "html2pdf.js";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import { CONFIG } from "@/constants/config.js";

export default {
  name: "InvoiceView.vue",
  components: {
    DialogueModal,
    LoadingModal,
    PageTitle: PageTitleComponent,
  },
  data() {
    return {
      id: -1,
      showDelete: false,
      showPrintDropdown: false,
      showEDIDropdown: false,
    };
  },
  computed: {
    BACKEND_URL() {
      return CONFIG.BACKEND_URL;
    },
    data() {
      return this.$store.getters.invoice;
    },
    invoiceDetails() {
      return this.$store.getters.invoiceSummary;
    },
    invoiceTotals() {
      return this.$store.getters.invoiceTotals;
    },
    invoiceDetailsFooter() {
      let footer = {
        "Total Amount": this.priceFormat(this.invoiceTotals.total),
        "Total Credit": this.priceFormat(this.invoiceTotals.amountCredited),
        "Redeemed Discount Value": this.priceFormat(
          this.invoiceTotals.discountRedeemed
        ),
        "Redeemed Points Value": "$0.00",
        "Total Paid": this.priceFormat(this.invoiceTotals.paid),
        "Total Balance": this.priceFormat(this.invoiceTotals.balance),
      };
      if (footer["Amount Credited"] === "$0.00") {
        delete footer["Amount Credited"];
      }
      if (footer["Redeemed Discount Value"] === "$0.00") {
        delete footer["Redeemed Discount Value"];
      }
      if (footer["Redeemed Points Value"] === "$0.00") {
        delete footer["Redeemed Points Value"];
      }
      return footer;
    },
    suggestedAmountFooter() {
      if (this.invoiceDetails.showSuggestedAnalysis === 0) {
        return {};
      }
      return {
        "Total Sugg": this.priceFormat(this.invoiceTotals.totalSuggested),
        "Sugg Credit": this.priceFormat(this.invoiceTotals.suggestedCredited),
        "Margin %": this.percentageFormat(
          parseFloat(this.invoiceTotals.totalSuggestedProfit)
        ),
        "Sugg Balance": this.priceFormat(this.invoiceTotals.suggestedBalance),
      };
    },
    loading() {
      return !this.invoiceDetails;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteInvoice(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
    host() {
      return CONFIG.HOST;
    },
  },
  methods: {
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    percentageFormat(value) {
      return value.toFixed(2) + "%";
    },
    async emailInvoice() {
      const invoiceElement = document.getElementById(
        this.invoiceDetails.invoiceNo
      );
      if (invoiceElement) {
        const invoiceDetails = this.invoiceDetails;
        const options = {
          margin: 10,
          filename: invoiceDetails.invoiceNo + ".pdf",
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf()
          .from(invoiceElement)
          .set(options)
          .outputPdf()
          .then(async (pdf) => {
            const pdfBlob = new Blob([pdf], { type: "application/pdf" });
            const formData = new FormData();
            formData.append(
              "invoicePDF",
              pdfBlob,
              invoiceDetails.invoiceNo + ".pdf"
            );
            await this.$store.dispatch("emailInvoice", {
              id: invoiceDetails.id,
              formData,
            });
          });
      } else {
        console.error("Invoice content not found!");
      }
    },
    togglePrintDropdown() {
      this.showPrintDropdown = !this.showPrintDropdown;
    },
    toggleEDIDropdown() {
      this.showEDIDropdown = !this.showEDIDropdown;
    },
    showDeleteModal() {
      this.showDelete = true;
    },
    async deleteInvoice() {
      await this.$store.dispatch("deleteInvoice", this.invoiceDetails.id);
      this.hideDeleteModal();
      this.$router.push("/Invoices");
    },
    hideDeleteModal() {
      this.showDelete = false;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getInvoice", this.id);
    await this.$store.dispatch("getInvoiceItems", {
      id: this.id,
      filter: null,
    });
    // if ((await this.$store.getters.invoiceSummary.status) === "new") {
    //   await router.push("/invoices/");
    //   alert("The invoice has not been initialized properly");
    // }
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetInvoice");
    await this.$store.dispatch("resetBiller");
    await this.$store.dispatch("resetCustomer");
  },
};
</script>
