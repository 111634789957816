<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit(updatedProductGroupItem)"
            @keydown.enter.prevent
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="barcode">Barcode</label>
                <input
                  id="barcode"
                  name="barcode"
                  placeholder="Enter Barcode Name"
                  type="text"
                  class="form-control"
                  v-model="product.barcode"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="productName">Product Name</label>
                <AutoCompleteInput
                  type="text"
                  placeholder="Choose a Product"
                  :chosen="{ value: updatedProductGroupItem.productName }"
                  :options="products"
                  :disabled="disabled || disabledList.has('productName')"
                  @choice="setProductID"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="category">Category</label>
                <input
                  id="category"
                  name="category"
                  placeholder="Enter Category Name"
                  type="text"
                  class="form-control"
                  v-model="product.category"
                  disabled
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import { SETTINGS } from "@/constants/config";

export default {
  name: "ProductGroupItemForm",
  components: { AutoCompleteInput },
  props: {
    productGroupItem: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
    disabledList: {
      type: Set,
      default: () => new Set(),
    },
  },
  data() {
    return {
      updatedProductGroupItem: { ...this.productGroupItem },
    };
  },
  methods: {
    submit() {
      if (this.product.productGroupID != 0) {
        alert(
          "This product is already assigned to a product group, please remove it from that group first"
        );
        return;
      }
      if (!this.disabled) {
        this.updatedProductGroupItem.productID = this.product.id;
        console.log(this.updatedProductGroupItem);
        this.submitFunction(this.updatedProductGroupItem);
      }
    },
    discard() {
      this.$router.push(
        `/productGroups/${this.updatedProductGroupItem.priceGroupID}`
      );
      this.updatedProductGroupItem = { ...this.productGroupItem };
    },
    edit() {
      this.$router.push("/productGroups/edit/" + this.productGroupItem.id);
    },
    async setProductID(value, id) {
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInput,
        searchQuery: value,
      };
      await this.$store.dispatch("getProducts", filter);
      this.updatedProductGroupItem.value = value;
      this.updatedProductGroupItem.productID = id;
      if (id > 0) {
        await this.$store.dispatch("getProduct", id);
      }
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
    products() {
      return this.$store.getters.allProducts;
    },
    product() {
      return this.$store.getters.product;
    },
  },
  async created() {
    this.updatedProductGroupItem.newProductGroupID = this.$route.params.id;
    let filter = {
      resultsPerPage: SETTINGS.resultsPerInput,
    };
    await this.$store.dispatch("getProducts", filter);
  },
  async unmounted() {
    await this.$store.commit("reset");
  },
};
</script>
