// Development Environment
const DEV_CONFIG = {
  BACKEND_URL: "https://www.starnovelty.arkify.net/api/",
  HOST: "www.starnovelty.arkify.net",
};

// Production Environment
const PROD_CONFIG = {
  BACKEND_URL: process.env.VUE_APP_BACKEND || DEV_CONFIG.BACKEND_URL,
  HOST: process.env.VUE_APP_HOST || DEV_CONFIG.HOST,
};

const CONFIGS = {
  dev: DEV_CONFIG,
  prod: PROD_CONFIG,
};

export const SETTINGS = {
  resultsPerPage: 10,
  resultsPerInput: 50,
  resultsPerInputCustomer: 50,
  resultsPerInputInvoices: 50,
  pagesDisplayed: 5,
};

export const CONFIG = CONFIGS.prod;

export default {
  CONFIG: CONFIG,
};
